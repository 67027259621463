module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"145 Vodka","short_name":"145Vodka","start_url":"/","lang":"en","background_color":"#000000","theme_color":"#000000","display":"minimal-ui","icon":"src/svg/inline/logo.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d248bb3530225e1dd97dff88aa3880ad"},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/builds/techmynder/clients/145vodka.com/src/i18n","languages":["en"],"defaultLanguage":"en","fallbackLanguage":"en","redirect":true,"redirectDefaultLanguageToRoot":true,"ignoredPaths":["/(legal-notice|privacy-policy)/"]},
    },{
      plugin: require('../gatsby-browser.ts'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
